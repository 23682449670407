import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Route, Routes, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from './components/Loading/Loading';
import SignUp from './views/SignUp/SignUp';
import SignIn from 'views/SignIn/SignIn';
import Authorization from 'views/Authorization/Authorization';
import SmartRecruitersAuthorization from 'views/SmartRecruitersAuthorization/SmartRecruitersAuthorization';
import ForgotPassword from './views/ForgotPassword/ForgotPassword';
import ResetPassword from './views/ResetPassword/ResetPassword';
import Dashboard from './views/Dashboard/Dashboard';
import Account from './views/Account/Account';
import NewCheck from './views/NewCheck/NewCheck';
import BulkImport from './views/BulkImport/BulkImport';
import { DeletionPolicy } from './views/DeletionPolicy/DeletionPolicy';
import Check from './views/Check/Check';
import ViewReport from './views/ViewReport/ViewReport';
import { PublicReferenceReport } from './views/PublicReferenceReport/PublicReferenceReport';
import Error404 from './views/errors/Error404';
import Error400 from './views/errors/Error400';
import Error500 from './views/errors/Error500';
import RefereeFormSubmission from './views/RefereeFormSubmission/RefereeFormSubmission';
import Questionnaires from './views/Questionnaires/Questionnaires';
import RefereeCompletion from './views/RefereeCompletion/RefereeCompletion';
import moment from 'moment';
import RefereeRequest from './views/RefereeRequest/RefereeRequest';
import { 
  CandidateMenu,
  CandidateReference,
  CandidateVirtualId,
  CandidateDetails,
  CandidateIds,
  CandidateSignature,
  CandidateCustomReference,
  CandidateSuccess,
  CandidateAcc,
  CandidateIntroduction,
  CandidateQuestionnaire,
  CandidateIdVerificationCheck,
  CandidateElVerificationCheck,
  CandidateBackyPortal,
  CandidateSignDocument,
  CadidateRejectedIds,
  USCriminalCheckDisclosures
} from './views/CandidateApp';
import AdminUsers from './views/AdminApp/AdminUsers/AdminUsers';
import AdminCompanies from './views/AdminApp/AdminCompanies/AdminCompanies';
import AdminCompany from './views/AdminApp/AdminCompany/AdminCompany';
import AdminCandidates from './views/AdminApp/AdminCandidates/AdminCandidates';
import AdminCandidate from './views/AdminApp/AdminCandidate/AdminCandidate';
import AdminReporting from './views/AdminApp/AdminReporting/AdminReporting';
import AdminLogs from './views/AdminApp/AdminLogs/AdminLogs';
import AdminCheckTypes from './views/AdminApp/AdminCheckTypes/AdminCheckTypes';
import AdminCandidateChecks from './views/AdminApp/AdminCandidateChecks/AdminCandidateChecks';
import ValidateUser from './views/ValidateUser/ValidateUser';
import { CriminalCheckConsent } from './views/CriminalCheckConsent/CriminalCheckConsent';
import AssessmentChecks from './views/Partners/v1/checks/AssessmentChecks';
import { AssessmentMessage } from './views/Partners/v1/messages/AssessmentMessage';
import AssessmentResults from './views/Partners/v1/results/AssessmentResults';
import { 
  AssessmentLauncher,
  AssessmentProgress,
  AssessmentResult,
  AssessmentDetails
 } from './views/Partners/v2';
import Reporting from './views/Reporting/Reporting';
import Users from './views/Users/Users';
import SingleSignOn from './views/SingleSignOn/SingleSignOn';
import SingleSignOnDomain from './views/SingleSignOnDomain/SingleSignOnDomain';
import Renewals from 'views/Renewals/Renewals';
import { authenticate, selectAuthentication } from 'api/authentication-slice';
import { selectCompany } from 'api/company-slice';
import { selectUser } from 'api/user-slice';
import CandidateAFPPayedByCandidate from 'views/CandidateApp/CandidateAFPPayedByCandidate/CandidateAFPPayedByCandidate';

const App = () => {

  const dispatch = useDispatch();

  const authentication = useSelector(selectAuthentication);
  const user = useSelector(selectUser);
  const company = useSelector(selectCompany);

  const { pathname } = useLocation();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(pathname === '/checkmate_error')
      setLoading(false);
    else
      dispatch(authenticate()).finally(() => setLoading(false))

    ReactGA.initialize('UA-123809112-1');
  }, [pathname])

  useEffect(() => {
    if(!user?.id || !company?.id) return;
    window.Intercom('boot', {
      app_id: 'yh3o8vqn',
      name: user.name,
      email: user.email,
      user_id: user.id,
      user_type: "client",
      created_at: moment(user.created_at, 'DD/MM/YYYY').unix(),
      company_name: company.name,
      company_country: company.country
    });
  }, [user?.id, company?.id])

  if(loading) return <Loading active={true}/>

  return (
    <Routes>
      <Route path='/' element={authentication.user_id ? <Dashboard /> : <SignIn />} /> 

      <Route path='/sign_in' element={<SignIn />} />

      <Route path='sign_up'>
        <Route index element={<SignUp />} />
        <Route path='tas/:tenant_short_code' element={<SignUp />} />
      </Route>

      <Route path='sso'>
        <Route index element={<SingleSignOn />} />
        <Route path=':domain' element={<SingleSignOnDomain />} />
      </Route>

      <Route path='/authorization' element={<Authorization />} />
      <Route path='/forgot_password' element={<ForgotPassword />} />
      <Route path='/reset_password/:token' element={<ResetPassword />} />
      <Route path='/smartrecruiters' element={<SmartRecruitersAuthorization />} />
      <Route path='/validation/:token' element={<ValidateUser />} />
      <Route path='/invitation/:token' element={<ResetPassword invitation={true} />} />
      <Route path='dashboard'>
        <Route index element={<Dashboard />} />
        <Route path=':validate' element={<Dashboard />} />
      </Route>

      <Route path='new_check'>
        <Route index element={<NewCheck />} />
        <Route path=':target' element={<NewCheck />} />
      </Route>

      <Route path='bulk_import'>
        <Route index element={<BulkImport />} />
        <Route path=':type' element={<BulkImport />} />
      </Route>

      <Route path='check'>
        <Route path=':id' element={<Check />} />
        <Route path=':id/:tab' element={<Check />} />
        <Route path=':id/:tab/:target' element={<Check />} />
      </Route>

      <Route path='questionnaires'>
        <Route index element={<Questionnaires />} />
        <Route path=':tab' element={<Questionnaires />} />
        <Route path=':tab/:id' element={<Questionnaires />} />
      </Route>

      <Route path='account'>
        <Route index element={<Account />} />
        <Route path=':tab' element={<Account />} />
      </Route>
      
      <Route path='/company/report/:type/:token' element={<ViewReport />} />
      <Route path='/company/report/reference/:token' element={<PublicReferenceReport />} />
      <Route path='/reporting' element={<Reporting />} />
      <Route path='/users' element={<Users />} />
      <Route path='/renewals' element={<Renewals />} />

      <Route path='form_submission'>
        <Route path='candidate'>
          <Route path=':token' element={<CandidateIntroduction />} />
          <Route path='introduction/:token' element={<CandidateIntroduction />} />
          <Route path='menu'>
            <Route path=':token' element={<CandidateMenu />} />
            <Route path=':token/:from_reference' element={<CandidateMenu />} />
          </Route>
          <Route path='questionnaire/:token' element={<CandidateQuestionnaire />} />
          <Route path='reference/:token' element={<CandidateReference />} />
          <Route path='reference/:token/:replacement' element={<CandidateReference />} />
          <Route path='custom_reference/:token/:replacement' element={<CandidateCustomReference />} />
          <Route path='custom_reference/:token' element={<CandidateCustomReference />} />
          <Route path='acc/:token' element={<CandidateAcc />} />
          <Route path='afp/:token' element={<CandidateAFPPayedByCandidate />} />
          <Route path='identity/:token' element={<CandidateIds />} />
          <Route path='virtual_id/:token' element={<CandidateVirtualId />} />
          <Route path='details/:token' element={<CandidateDetails />} />
          <Route path='id_verification/:token' element={<CandidateIdVerificationCheck />} />
          <Route path='el_verification/:token/:code' element={<CandidateElVerificationCheck />} />
          <Route path='el_verification/:token' element={<CandidateElVerificationCheck />} />
          <Route path='signature/:token' element={<CandidateSignature />} />
          <Route path='backy_portal/:token' element={<CandidateBackyPortal />} />
          <Route path='criminal_check_disclosures/:token' element={<USCriminalCheckDisclosures />} />
          
          <Route path='document_signing/:token' element={<CandidateSignDocument />} />
          <Route path='rejected_ids/:token' element={<CadidateRejectedIds />} />
          <Route path='success/:token' element={<CandidateSuccess />} />
        </Route>

        <Route path='referee/:token'>
          <Route index element={<RefereeFormSubmission />} />
          <Route path=':referee' element={<RefereeFormSubmission />} />
          <Route path=':referee/:candidate' element={<RefereeFormSubmission />} />
        </Route>
      </Route>
      
      <Route path='/referee_completion/:token' element={<RefereeCompletion />} />
      <Route path='/referee_request/:token' element={<RefereeRequest />} />

      
      <Route path='assessments'>
        {/* Partners V2 */}
        <Route path='launcher/:partner/:token' element={<AssessmentLauncher />} />
        <Route path='details/:partner/:token' element={<AssessmentDetails />} />
        <Route path='progress/:partner/:token' element={<AssessmentProgress />} />
        <Route path='result/:partner/:token' element={<AssessmentResult />} />

        {/* Partners V1 */}
        <Route path=':token'>
          <Route path='checks/:check_type' element={<AssessmentChecks />} />
          <Route path='results/:check_type' element={<AssessmentResults />} />
          <Route path='results' element={<AssessmentResults />} />
        </Route>

        <Route path='messages/:severity' element={<AssessmentMessage />} />
      </Route>
      
      <Route path='admin'>
        <Route index element={<AdminCandidates />} />
        <Route path='users' element={<AdminUsers />} />
        <Route path='check_types' element={<AdminCheckTypes />} />
        <Route path='companies/:company_id' element={<AdminCompany />} />
        <Route path='companies' element={<AdminCompanies />} />  
        <Route path='candidates/:candidate_id' element={<AdminCandidate />} />
        <Route path='candidates' element={<AdminCandidates />} />
        <Route path='checks' element={<AdminCandidateChecks />} />
        <Route path='log' element={<AdminLogs />} />
        <Route path='reporting' element={<AdminReporting />} />
      </Route>

      <Route path='/criminal_check_consent' element={<CriminalCheckConsent />} />
      <Route path='/deletion_policy' element={<DeletionPolicy />} />
      <Route path='/not_found_error' element={<Error404 />} />
      <Route path='/bad_request' element={<Error400 />} />
      <Route path='/checkmate_error' element={<Error500 />} />
      <Route path='*' element={<Error404 />} />
    </Routes>
  );
}

export default App