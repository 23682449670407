import { 
  useLazyGetRefereeFormByTokenQuery,
  useSaveUtmSourceMutation,
  useGetRefereeBrandByTokenQuery,
  useGetCompanyCheckConfigByTokenQuery,
  useGetRefereeByTokenQuery,
  useLazyVerifyRefereeQuery,
  useSaveResponseMutation,
} from 'api/referees-api';
import { useLinkedinCallback } from 'hooks/linkedin-callback';
import { useLazyGetLinkedinProfileQuery } from 'api/linkedin-api';
import { Modal } from 'components/Modal/Modal';
import { Loading } from 'components/Loading/Loading';
import LinkedinVerification from 'components/LinkedinVerification/LinkedinVerification';
import AlreadyCompletedForm from 'components/AlreadyCompletedForm/AlreadyCompletedForm';
import { TypeformForm } from './TypeformForm/TypeformForm';
import styles from './RefereeFormSubmission.module.scss';
import { useEffect, useState } from 'react';
import { useDocumentTitle } from 'hooks/document-title';
import { Form } from 'components/Form/Form/Form';
import { useQueryParams } from '../../hooks/query-params';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomLink } from '../CandidateApp/components/CustomLink/CustomLink';
import { CustomButton } from '../CandidateApp/components/CustomButton/CustomButton';
import { useRefereeUploadActions } from 'hooks/upload-actions';
import { useUserAgent } from 'hooks/user-agent';
import moment from 'moment';
import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';

export const RefereeFormSubmission = () => {
  
  useDocumentTitle();

  const navigate = useNavigate();
  const { token } = useParams();

  const { user_agent } = useUserAgent();
  const [ utm_source ] = useQueryParams(['utm_source']);

  const { data: brand, isLoading: loadingBranding } = useGetRefereeBrandByTokenQuery(token);
  const { data: checkConfig, isLoading: loadingCheckConfig } = useGetCompanyCheckConfigByTokenQuery(token);
  const { data: referee, isLoading: loadingReferee } = useGetRefereeByTokenQuery(token);

  const { getUpload, signUpload, createUpload, deleteUpload } = useRefereeUploadActions(token);

  const [saveUtmSource] = useSaveUtmSourceMutation();
  const [getRefereeForm] = useLazyGetRefereeFormByTokenQuery();
  const [getLinkedinProfile] = useLazyGetLinkedinProfileQuery();
  const [verifyReferee] = useLazyVerifyRefereeQuery();
  const [saveResponse] = useSaveResponseMutation();

  const [linkedinProfile, setLinkedinProfile] = useState('');

  const [loading, setLoading] = useState(false);
  const [showIdVerification, setShowIdVerification] = useState(false);
  const [showAlreadyCompleted, setShowAlreadyCompleted] = useState(false);
  const [showIntroduction, setShowIntroduction] = useState(true);

  // Either one or the other
  const [typeformParams, setTypeformParams] = useState();
  const [form, setForm] = useState();
  const [formParams, setFormParams] = useState({});

  // Save UTM
  useEffect(() => { saveUtmSource({ token, utm_source }) }, []);

  useEffect(() => { 
    if(referee?.completed) 
      setShowAlreadyCompleted(true) 

    if(referee?.id)
      window.Intercom('boot', {
        app_id: 'yh3o8vqn',
        name: referee.name,
        email: referee.email,
        user_id: referee.id,
        user_type: "referee",
        created_at: moment(referee.created_at, 'DD/MM/YYYY').unix()
      });
  }, [referee])

  useLinkedinCallback(async(code) => {
    setLoading(true);
    const result = await getLinkedinProfile(code).unwrap();
    getForm(result);    
  }, [referee]);

  const getForm = async(linkedin_profile) => {
    setLoading(true);
    setShowIdVerification(false);
    if(referee.form_id) {
      setLinkedinProfile(linkedin_profile);
      const { form, params } = await getRefereeForm(token).unwrap();
      setForm(form);
      setFormParams(params)
    } else { 
      const result = await verifyReferee({ token, linkedin_profile, user_agent }).unwrap();
      setTypeformParams(result);
    }
    setLoading(false);
  }

  const closeIntroduction = async() => {
    setShowIntroduction(false);

    if(checkConfig?.disabled_linkedin_verification) {
      getForm('');
    } else {
      setShowIdVerification(true);
    }
  }

  const saveAnswers = async(answers) => {
    setLoading(true);
    await saveResponse({ token, params: { user_agent, linkedin_profile: linkedinProfile, answers }});
    navigate(`/referee_completion/${token}`);
  }

  if(loading || loadingReferee || loadingCheckConfig || loadingBranding) return <Loading active={true} />

  return ( 
    <div className={styles.root}>
      <AnimatedModal visible={showIntroduction} small noClose title='Introduction to Referee'>
        <p className='u-margin-top--large'>
          Checkmate is a secure verification platform designed to simplify the process for employers and employees. 
          The privacy and security of your data is our highest priority, and we have several systems and processes to keep your information safe and secure.
          <br/><br/>
          Checkmate is ISO27001 Certified and uses encrypted connections to ensure your privacy and confidentiality is maintained throughout this application.  
          We are externally audited annually and commission regular independent tests to ensure the security of our system.
          <br/><br/>
          Checkmate performs several employment checks and verification on behalf of employers; we will ask the required questions from the employer so you can provide a reference for the candidate.
          <br/><br/>
          All information you supply is kept strictly confidential and is only accessible by authorised staff.
          <br/><br/>
          Before proceeding, please read our <CustomLink brand={brand} href='https://www.checkmate.tech/utility/referee-consent-statement'>Referee Consent Statement</CustomLink>, <CustomLink brand={brand} href='https://www.checkmate.tech/utility/privacy-policy'>Privacy Policy</CustomLink> and <CustomLink brand={brand} href='https://www.checkmate.tech/utility/terms-and-conditions'>Terms and Conditions</CustomLink> here. By closing this window, you confirm that you have read, understood, and agreed to proceed.
          <br/><br/>
          If you have any questions or concerns please contact our support team via email at <CustomLink brand={brand} href='mailto:team@checkmate.tech'>team@checkmate.tech</CustomLink>.
        </p>
        <CustomButton brand={brand} onClick={closeIntroduction} className='u-margin-top--large'>Close Window</CustomButton>
      </AnimatedModal>

      <Modal show={showIdVerification} disableClose fullWindow>
        <LinkedinVerification onSubmit={getForm} />
      </Modal>
      <Modal show={showAlreadyCompleted} disableClose>
        <AlreadyCompletedForm />
      </Modal>
      {typeformParams && 
        <TypeformForm 
          params={typeformParams} 
          onSubmit={() => navigate(`/referee_completion/${token}`)}
        />
      }
      {form && 
        <div className='card card-with-border card--small'>
          <div className='card_content'>
            <img src='assets/images/icons/id_icon.svg' className='u-margin-bottom' />
            <Form
              candidateId={referee?.candidate_id}
              textParams={formParams}
              form={form}
              brand={brand}
              buttonTitle='Submit'
              onSubmit={saveAnswers}
              getCandidateUpload={getUpload}
              deleteCandidateUpload={deleteUpload}
              signCandidateUpload={signUpload}
              createCandidateUpload={createUpload}
            />
          </div>
        </div>
      }
    </div>
  )
}

export default RefereeFormSubmission