export const CandidateCheckScreens = {
  INTRODUCTION: "introduction",
  QUESTIONNAIRE: "questionnaire",
  REFERENCE: "reference",
  CUSTOM_REFERENCE: "custom_reference",
  ACC: "acc",
  MENU: "menu",
  IDENTITY: "identity",
  SIGN: "signature",
  DETAILS: "details",
  SUCCESS: "success",
  REJECTED_IDS: "rejected_ids",
  ID_VERIFICATION: "id_verification",
  EL_VERIFICATION: "el_verification",
  VIRTUAL_ID: "virtual_id",
  BACKY_PORTAL: 'backy_portal',
  DOCUMENT_SIGNING: 'document_signing',
  US_CRIMINAL_CHECK_DISCLOSURES: 'criminal_check_disclosures',
  AFP: 'afp'
}