import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCompany } from 'api/company-slice';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Input, InputGroup, Button, FormMessage } from 'components/FormComponents';
import { useForm, useWatch } from 'react-hook-form';
import ReactGA from 'react-ga';
import ReCAPTCHA from "react-google-recaptcha";
import styles from './SignUp.module.scss';
import { Loading } from 'components/Loading/Loading';
import PasswordStrengthBar from 'react-password-strength-bar';
import cn from 'classnames';
import { useDocumentTitle } from 'hooks/document-title';
import { isPrivateDomain } from 'helpers/form-validators';
import { useQueryParams } from 'hooks/query-params';
import { selectAuthentication } from 'api/authentication-slice';
import { selectUser } from 'api/user-slice';
import { selectCompany } from 'api/company-slice';

export const SignUp = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useDocumentTitle('Sign Up');

  const auth = useSelector(selectAuthentication);

  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [notARobot, setNotARobot] = useState(process.env.REACT_APP_ENV !== 'prod');
  const [scorePass, setScorePass] = useState(false);

  const { register, handleSubmit, formState: { errors, isValid }, control } = useForm();

  const watchPassword = useWatch({ control, name: 'user.password'});

  const [ utm_source, partner_id ] = useQueryParams(['utm_source', 'partner_id']);
  const { tenant_short_code } = useParams();

  const user = useSelector(selectUser);
  const company = useSelector(selectCompany);
  
  // Redirect
  useEffect(() => { 
    if(auth.checked && auth.logged) navigate(`/dashboard`);
  }, [auth])

  // Init
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  const analytics = () => {
    const user_name = user.name;
    const email = user.email;
    const company_name = company.name;

    window.analytics.identify({
      user_name,
      email
    });

    window.analytics.track('Signed Up', {
      username: user_name,
      email: email,
      company: company_name
    });
  }

  // Submit
  const onSubmit = async(data) => {
    if(!notARobot) return;
    if(!scorePass) {
      setError('Password is not strong enough');
      return;
    }

    setLoading(true);
    try {
      const { payload: response } = await dispatch(createCompany({
        user: {
          ...data.user,
          partner_id,
          utm_source,
        },
        company: {
          ...data.company,
          tenant_short_code
        }
      }));
      if(response.error) throw response.error.message;
      window.dataLayer.push({'event': 'sign_up'});
      window.trackingListener();
      analytics();
      navigate(`/dashboard`);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={styles.root}>
      <Loading active={loading} />
      <div className={cn('card u-margin-top', 'card-with-shadow', styles.card)}>
        <div className={cn('card_content', styles.content)}>
          <img onClick={() => window.location.replace('https://www.checkmate.tech')} className={cn(styles.logo, 'u-margin-bottom--large')} src='/assets/images/checkmate/icon-label.svg' alt='KnowMy Logo' />
          <h2 className='design-marker title-2 u-margin-bottom--large'>Get started now!</h2>
          <form id='sign-up-form' onSubmit={handleSubmit(onSubmit)} className='u-width-100 u-flex-box u-flex-align-center u-flex-direction-vertically'>
            <FormMessage message={error} className='u-margin-top--0' onClose={() => setError(null)} />
            <div className={styles.inlineInputs}>
              <InputGroup title='First Name'>
                <Input 
                  name='user.first_name'
                  placeholder='John'
                  register={register}
                  validators={{ required: true }}
                  error={errors?.user?.first_name?.type}
                  inputProps={{ tabIndex: 1 }}
                />
              </InputGroup>
              <InputGroup title='Last Name'>
                <Input 
                  name='user.last_name'
                  placeholder='Doe'
                  register={register}
                  validators={{ required: true }}
                  error={errors?.user?.last_name?.type}
                  inputProps={{ tabIndex: 2 }}
                />
              </InputGroup>
            </div>
            <InputGroup title='Company Name'>
              <Input 
                name='company.name'
                placeholder='Checkmate'
                register={register}
                validators={{ required: true }}
                error={errors?.company?.name?.type}
                inputProps={{ tabIndex: 3 }}
              />
            </InputGroup>
            <InputGroup title='Email'>
              <Input 
                name='user.email'
                placeholder='john@checkmate.tech'
                register={register}
                validators={{ validate: isPrivateDomain }}
                error={errors?.user?.email?.message}
                inputProps={{ tabIndex: 4 }}
              />
            </InputGroup>
            <InputGroup title='Password'>
              <Input 
                name='user.password'
                type='password'
                placeholder='Enter password'
                register={register}
                validators={{ required: true }}
                error={errors?.user?.password?.type}
                inputProps={{ tabIndex: 5 }}
              />
              <PasswordStrengthBar 
                password={watchPassword}
                onChangeScore={(score) => {score > 2 ? setScorePass(true) : setScorePass(false)}}
              />
            </InputGroup>
            <ReCAPTCHA sitekey="6Le9CJUaAAAAAGoHMzbB0ZuNQTHkNNyz6fgDAH2d" onChange={() => setNotARobot(true)} />
            <Button id='submitBtn' submit buttonProps={{ tabIndex: 6 }} className='u-width-100 u-margin-top' disabled={!notARobot || !isValid}>Sign up</Button>
            <Link className={cn('u-link', 'dark', 'u-margin-top')} to="/sign_in">Already have an account? Sign in</Link>
          </form>
        </div>
        <div className={cn('card_footer', styles.footer)}>
          <p>By clicking the button above, you agree to our <a className='link' href='https://www.checkmate.tech/utility/service-terms' target='_blank'>Terms of Service</a> and <a className='link' href='https://www.checkmate.tech/utility/privacy-policy' target='_blank'>Privacy Policy</a></p>
        </div>
      </div>
    </div>
  )
}

export default SignUp