import { createContext, useEffect, useContext, useState, useRef, useMemo } from 'react';
import { useClickOutside } from 'hooks/click-outside';
import PropTypes from 'prop-types';
import styles from './MultipleSelect.module.scss';
import FeatherIcon from 'feather-icons-react';
import cn from 'classnames';

const SelectContext = createContext(null);

const useSelectContext = () => {
  const context = useContext(SelectContext)
  if (!context) throw new Error(`MultipleSelect compound components cannot be rendered outside the MultipleSelect component`)
  return context
}

export const MultipleSelect = ({
  value,
  valueKey,
  onChange,
  className,
  placeholder = '',
  disabled = false,
  children,
  useSearch = false,
  onSearchChange = () => {},
}) => {

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => setShowMenu(false));

  const [options, setOptions] = useState([]);

  const [ showMenu, setShowMenu ] = useState(false);
  
  const [search, setSearch]  = useState('')

  const toggleOption = (option) => {
    if(options.map(o => o.value).includes(option.value)) {
      setOptions(curr => curr.filter(o => o.value !== option.value))
    } else {
      setOptions(curr => [...curr, option])
    }
  }

  const handleChange = (e) => {
    const search = e.target.value.toLowerCase()
    setSearch(search)
    onSearchChange(search)
    setShowMenu(true);
  }

  // Expose to context
  const contextValue = useMemo(() => ({ options, toggleOption }), [options])

  // onChange
  useEffect(() => {
    if(!options) return;

    const optionValues = options.map(o => o.value);

    if(!valueKey) {
      onChange(optionValues);
      return;
    }

    // Values with id
    let persistedModels = value?.filter(model => !!model.id);

    if(!persistedModels || persistedModels.length < 1) {
      onChange(optionValues.map(val => ({ [valueKey]: val })));
      return;
    }

    // Mark destroyed ones
    persistedModels = persistedModels.map(model => {
      let isSelected = optionValues.includes(model[valueKey]);
      return { ...model, _destroy: !isSelected }
    });

    // Get values that are already set
    const setValues = persistedModels.map(model => model[valueKey]);

    // Calculate that need to be added
    const valuesToAdd = optionValues.filter(val => !setValues.includes(val));

    // Add values that are not persisted
    const _value = [...persistedModels, ...valuesToAdd.map(val => ({ [valueKey]: val }))];

    onChange(_value);
  }, [options])

  useEffect(() => {
    if(!showMenu) {
      setSearch('');
      onSearchChange('');
    }
  }, [showMenu])

  return (
    <SelectContext.Provider value={contextValue}>
      <div ref={wrapperRef} className={cn(styles.root, { [className]: className })}>
        <div className={cn(styles.button, { [styles.disabled]: disabled })} onClick={() => { if(!disabled) setShowMenu(!showMenu) }}>
          <span className="u-text-ellipsis">{options.length > 0 ? options.map(o => o.label).join(', ') : <span className={styles.placeholder}>{placeholder}</span>}</span>
        </div>
        <div className={cn(styles.menu, { [styles.menuVisible]: showMenu})}>
          {useSearch &&
            <>
              <div className={cn(styles.search)}>
                <FeatherIcon className={styles.icon} icon='search' />
                <input
                  type='text'
                  value={search}
                  onChange={handleChange}
                  className={styles.input}
                  placeholder="Search..."
                />
              </div>
            </>
          }
          <ul >
            {children}
          </ul>
        </div>
      </div>
    </SelectContext.Provider>
  )
}

MultipleSelect.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  useSearch: PropTypes.bool,
  onSearchChange: PropTypes.func,
};


const Item = ({
  value,
  children,
  description,
  active=false
}) => {

  const { options, toggleOption } = useSelectContext();

  const [isSelected, setIsSelected] = useState(false);

  const handleSelected = () => {
    toggleOption({ label: children, value: value })
  }

  useEffect(() => {
    setIsSelected(options.map(o => o.value).includes(value))
  }, [options])

  useEffect(() => { 
    if((active && !isSelected) || (isSelected && active === false)) handleSelected() 
  }, [active])

  return (
    <li className={styles.item} onClick={handleSelected}>
      {children}
      <p className={styles.itemDescription}>{description}</p>
      {isSelected && 
        <FeatherIcon icon='check' size={16} className='margin-left--small'/>
      }
    </li>
  )
}

Item.propTypes = {
  value: PropTypes.string,
  description: PropTypes.string,
  active: PropTypes.bool,
}

MultipleSelect.Item = Item;